import styled from "styled-components";

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 110px); /* account for nav height */
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  img {
    z-index: -1;
    width: 100%;
    object-fit: contain;
    position: absolute;
    opacity: 0.2;
  }
`;
