import React from "react";
import Layout from "@components/Layout";
import { NotFoundWrapper } from "@styles/404";
import NotFoundImage from "../images/404.jpg";

export default ({ location }) => {
  return (
    <Layout location={location}>
      <NotFoundWrapper>
        <h1>404</h1>
        <h2>Page not found</h2>
        <span role="img" aria-label="sad emoji">
          😥
        </span>
        <img
          src={NotFoundImage}
          alt="A scene from Mr. Robot where a poster says error not found."
        />
      </NotFoundWrapper>
    </Layout>
  );
};
